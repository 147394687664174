// https://getbootstrap.com/docs/5.0/customize/sass/#modify-map
$body-bg: #fff;
$body-color: #000;
$primary: #008075;
$secondary: #800075;
$light: #e8e8e8;

// https://stackoverflow.com/a/29952126
$font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 700;

// Our logo was getting an additional 5 pixels above and below it which was
// definitely not necessary as it's already quite tall enough.
$navbar-brand-padding-y: 0;

// In order to change the Bootstrap font sizes for H1 and H2 in particular we are doing this.
// The value we are setting for $font-size-base is the same as Bootstrap's default, which assumes the browser default, typically 16px.
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base !default;

@import "~bootstrap/scss/bootstrap";

// body contains a div which contains everything above the footer, and then
// the footer itself.
// Make body a flexbox which 'stacks' those items above the footer with the key
// feature being that it fills the space between, meaning that the footer
// stays at the bottom of the page even if the content is short and the
// browser window is taller.
// Directly copied from: https://stackoverflow.com/a/51410022
// But then modified according to: https://stackoverflow.com/a/56141779/392847
.sh-wrapper,
html,
body {
  height: 100%;
  margin: 0;
}
.sh-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.sh-logo {
  height: 1.75em;
  pointer-events: none;
}

// Tight Line Spacing
.sh-tls {
  line-height: 1.3em;
}
